import MDXRenderer from "gatsby-mdx/mdx-renderer"
import React from "react"
import { Link, graphql } from "gatsby"
import SiteHeader from "components/headers/SiteHeader";
import ErrorMessage from "components/messages/ErrorMessage";
import "./base-styles.scss"
import "./LayoutCommon"
import { baseClass, childClass } from "./LayoutCommon";


export default function DocsLayout(props) {

  return (
    <div>
      <SiteHeader />
      <ErrorMessage message='NO LAYOUT DEFINED' />
    </div>
  )
}

/**
 * Query for data for the page. Note that $id is injected in via context from
 * actions.createPage. See gatsby-node.js for more info.
 */
export const pageQuery = graphql`
  query DocsLayoutQuery($id: String) {
        mdx(id: {eq: $id }) {
        id
        frontmatter {
          title
          mainImage
        }
        code {
          body
        }
      }
    }
  `
